"use client";

import React from "react";
import { useTranslation } from 'react-i18next';
import { cn } from "../utils/cn.ts";

export const LanguageSwitcherButton = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = React.useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  return (
    <button
      type="button"
      onClick={() => changeLanguage(i18n.language === "en" ? "fr" : "en")}
      className="w-[115px] overflow-hidden group px-2 py-1 dark:bg-neutral-800 bg-white hover:bg-neutral-100 border border-neutral-500/10 dark:hover:bg-neutral-700 inline-flex items-center gap-2 rounded-md text-neutral-600 dark:text-neutral-300 tracking-tight font-medium relative transition-all"
    >
      <span
        className={cn(
          "relative size-6 scale-75 bg-gradient-to-tr rounded-full transition-transform flex items-center justify-center",
        )}
      >
        <span
          className={cn(
            "absolute w-full h-full z-10 top-0 left-0 bg-gradient-to-tr rounded-full transition-transform duration-500",
            lang === "fr" ? "scale-100" : "scale-90",
          )}
        />
        <span
          className={cn(
            "text-3xl absolute w-full h-full z-10 top-0 left-0 bg-gradient-to-tr rounded-full transition-opacity duration-500 flex items-center justify-center",
            lang === "en" ? "opacity-100" : "opacity-0",
          )}
        >
          🇬🇧
        </span>
        <span
          className={cn(
            "text-3xl absolute w-full h-full z-10 top-0 left-0 bg-gradient-to-tr rounded-full transition-opacity duration-500 flex items-center justify-center",
            lang === "fr" ? "opacity-100" : "opacity-0",
          )}
        >
          🇫🇷
        </span>
      </span>
      <span className="relative w-12 h-6">
        <span
          className={cn(
            "absolute top-0 left-0 transition-all duration-1000",
            lang === "fr"
              ? "-translate-y-4 blur-lg opacity-0"
              : "translate-y-0 opacity-100 blur-0",
          )}
        >
          English
        </span>
        <span
          className={cn(
            "absolute top-0 left-0 transition-all duration-1000",
            lang === "en"
              ? "translate-y-4 blur-lg opacity-0"
              : "translate-y-0 opacity-100 blur-0",
          )}
        >
          Français
        </span>
      </span>
    </button>
  );
};