import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {Input} from "@/components/ui/input"
import {Button} from "./@/components/ui/button";
import {Label} from "./@/components/ui/label";


function Login({onLogin}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState('/dashboard');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    //check if the user is already logged in, if so, redirect to the dashboard
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
    // Extract 'redirect' query parameter and store it
    const params = new URLSearchParams(location.search);
    const redirect = params.get('redirect');
    if (redirect) {
      setRedirectUrl(decodeURIComponent(redirect));
    }
  }, [location.search]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, {
        mailUtilisateur: email,
        password
      });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userId', response.data.id);
      localStorage.setItem('userName', response.data.prenomutilisateur);
      //console.log("token: ", response.data.token, "userId: ", response.data.id, "userName: ", response.data.prenomutilisateur);
      onLogin();
      navigate(redirectUrl);
      toast.success('Vous êtes connecté(e) avec succès!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'bg-green-600',
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'bg-red-600',
        });
      } else {
        toast.error('Une erreur s\'est produite lors de la connexion', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'bg-red-600',
        });
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="m-auto">
      <form onSubmit={handleSubmit}>

        <CardHeader>
          <CardTitle>Connexion</CardTitle>
          <CardDescription>Connectez-vous pour accéder à votre tableau de bord</CardDescription>
        </CardHeader>

        <CardContent className={'flex flex-col gap-6'}>
          <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email"/>
          <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required
                 placeholder="Mot de passe"/>
        </CardContent>

        <CardFooter>
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? (
              <i className="fas fa-spinner fa-spin mr-2"></i>
            ) : (
              <i className="fas fa-sign-in-alt mr-2"></i>
            )}
            Se connecter
          </Button>
        </CardFooter>

      </form>
    </Card>
  );
}

export default Login;