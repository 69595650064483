import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import {MoonLoader} from 'react-spinners';
import {useSpring} from 'react-spring';

import {Badge} from "@/components/ui/badge"
import {Checkbox} from "@/components/ui/checkbox"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger
} from "@/components/ui/dialog"
import {Button} from "./@/components/ui/button";
import {Label} from "./@/components/ui/label";
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "./@/components/ui/card";
import CryptoJS from "crypto-js";
import ShinyRotatingBorderButtonVariant1 from "./CuiCui/ShinyRotatingBorderButtonVariant1";
import ThreeDotLoaderGrowing from "./CuiCui/ThreeDotLoaderGrowing";

const TableauPage = () => {
  const navigate = useNavigate();

  const [tableau, setTableau] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [results, setResults] = useState({});
  const [favorites, setFavorites] = useState([]);
  const {id: encryptedId} = useParams();
  const userId = localStorage.getItem('userId');

  const [isCertified, setIsCertified] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [userRankAndCount, setUserRankAndCount] = useState([0, 0]);
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  let [reponse, setReponse] = useState("blyat");
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [resourcesToHighlight, setResourcesToHighlight] = useState([]);
  const [aiStarted, setAiStarted] = useState(false);
  const [id, setid] = useState("");

  const buttonAnimation = useSpring({
    backgroundColor: isLinkCopied ? '#4CAF50' : 'white',
    color: isLinkCopied ? 'white' : 'black', // Dynamically change based on isLinkCopied
    config: {duration: 150}, // Reduce animation duration
  });

  useEffect(() => {
    const decryptedId = CryptoJS.AES.decrypt(decodeURIComponent(encryptedId), 'secret-key').toString(CryptoJS.enc.Utf8);
    setid(decryptedId);
  }, [encryptedId]);

  function checkIfResultsAreFilled() {
    const resultsArray = Object.values(results);
    return resultsArray.every(result => result !== '');
  }

  const fetchUserRankAndCount = async () => {
    if (!checkIfResultsAreFilled()) {
      alert("Veuillez remplir toutes les notes pour obtenir votre classement.");
      return;
    }

    setAiStarted(true);
    setDisplayedText('');
    setIsLoading(true);
    setTimeout(async () => {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user_rank_and_count/${id}/${userId}`);
        const newUserRankAndCount = response.data.get_user_rank_and_count;
        let rankText = `Votre rang est ${newUserRankAndCount[0]} sur ${newUserRankAndCount[1]} utilisateurs.`;
        const adviceText = adviseBestClassToImprove(tableau); // Assuming tableau is up-to-date
        let combinedText = `${rankText} ${adviceText}`; // Combine rank and advice
        setIsTyping(true);
        setUserRankAndCount(newUserRankAndCount);

        // Clear displayedText before starting and ensure no previous interval is running
        clearInterval(window.typingInterval);
        setDisplayedText('');

        // Split combinedText into words
        const words = combinedText.split(' ');
        console.log("ressourcesToHighlight", resourcesToHighlight);
        let i = -1; // Start from 0 to include the first word
        // Define a function to display words with a random delay
        function displayNextWord() {
          if (i < words.length - 1) {
            i++;
            // Use the function form of setState to ensure we're always working with the most up-to-date state
            setDisplayedText((prevText) => {
              const nextWord = words[i];
              const alreadyAppended = prevText.trim().endsWith(nextWord);
              if (!alreadyAppended) {
                for (let i = 0; i < resourcesToHighlight.length; i++) {
                  if (nextWord.toLowerCase().includes(resourcesToHighlight[i].resourceName.toLowerCase())) {
                    console.log("########highlighting#########1 ", nextWord);
                    return `${prevText} <span class="bg-yellow-200 dark:bg-yellow-500 dark:text-yellow-900 p-1 rounded-md">${nextWord}</span>`;
                  }
                }
                return `${prevText} ${nextWord}`;
              } else {
                return prevText;
              }

            });
            setTimeout(displayNextWord, Math.random() * (75) + 60);
          } else {
            setIsTyping(false);
          }
        }

        displayNextWord();
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }, 500);
  };

  useEffect(() => {
    localStorage.removeItem('results' + id);
    const fetchTableau = async () => {
      try {
        if (reponse !== "done") {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/tableau/${id}`);
          setTableau(response.data);
          setReponse("done");
          localStorage.setItem(id, JSON.stringify(response.data));
        } else {
          //setTableau(reponse);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const checkAdmin = async () => {
      if (localStorage.getItem('isAdmin') === "true") {
        setIsAdmin(true);
      } else {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/${userId}`);
          setIsAdmin(response.data.isAdmin);
        } catch (error) {
          console.error(error);
        }
      }
    }

    const fetchResults = async (userId) => {
      try {
        const resultsResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/results/${id}`, {
          params: {idUtilisateur: userId}
        });
        const resultsObject = resultsResponse.data.reduce((obj, result) => {
          obj[result.idmatiere] = result.moyenne;
          return obj;
        }, {});
        setResults(resultsObject);
        localStorage.setItem('results' + id, JSON.stringify(resultsObject));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchFavorites = async (userId) => {
      try {
        const favoritesResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/favoris`, {
          params: {idUtilisateur: userId}
        });
        setFavorites(favoritesResponse.data);
        localStorage.setItem('favorites' + id, JSON.stringify(favoritesResponse.data));
      } catch (error) {
        console.error(error);
      }
    }

    const userId = localStorage.getItem('userId');

    const cachedTableau = localStorage.getItem(id);
    if (cachedTableau) {
      setTableau(JSON.parse(cachedTableau));
    } else {
      fetchTableau();
    }
    checkAdmin();
    if (userId) {
      //first check if results are in local storage
      const cachedResults = localStorage.getItem('results' + id);
      if (cachedResults) {
        setResults(JSON.parse(cachedResults));
      } else {
        fetchResults(userId);
      }
      const cachedFavorites = localStorage.getItem('favorites' + id);
      if (cachedFavorites) {
        setFavorites(JSON.parse(cachedFavorites));
      } else {
        fetchFavorites(userId);
      }
      //fetchUserRankAndCount();
    }

    if (isTyping) {
      let rankText = `Votre rang est ${userRankAndCount[0]} sur ${userRankAndCount[1]} utilisateurs.`;
      let i = 0; // Start from 0 to include the first character
      const typing = setInterval(() => {
        if (i < rankText.length) {
          setDisplayedText((prevText) => prevText + rankText.charAt(i));
          i++;
        } else {
          clearInterval(typing);
          setIsTyping(false);
        }
      }, 50);
      return () => clearInterval(typing);
    }
    //removed ", isTyping, userRankAndCount"
  }, [id]);

  if (!tableau) {
    return (
      <div className="h-full w-full flex flex-col items-center justify-center">
        <ThreeDotLoaderGrowing/>
      </div>
    );
  }

  const handleCertificationChange = async () => {
    const userId = localStorage.getItem('userId');
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tableaux/${id}/certify`,
        {isCertified: !isCertified},
        {headers: {'user-id': userId}}
      );
      setIsCertified(!isCertified);
    } catch (error) {
      console.error('Error updating certification:', error);
    }
  };

  const handleDeleteTableau = async () => {
    const userId = localStorage.getItem('userId');
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/tableaux/${id}`,
        {headers: {'user-id': userId}}
      );
      navigate('/');
    } catch (error) {
      console.error('Error deleting tableau:', error);
    }
  };

  const handleInputChange = (resourceNumber, event) => {
    setFocusedInput(resourceNumber);
    setIsEditing(true);
    setResults({
      ...results,
      [resourceNumber]: event.target.value,
    });
  };

  const calculateAverage = (ueNumber) => {
    const relevantCoefficients = tableau.coefficients.filter(coefficient => coefficient.ueNumber === ueNumber);
    const sumOfProducts = relevantCoefficients.reduce((sum, coefficient) => sum + (coefficient.coefficient * (results[coefficient.resourceNumber] || 0)), 0);
    const sumOfCoefficients = relevantCoefficients.reduce((sum, coefficient) => sum + coefficient.coefficient, 0);
    const average = sumOfProducts / sumOfCoefficients;
    return average.toFixed(2);
  };

  const saveResults = async () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.log('User not logged in. Unable to save results.');
      return;
    }

    setIsSaving(true);
    //check if the results changed and if not, do not save
    const cachedResults = localStorage.getItem('results' + id);
    if (cachedResults) {
      const cachedResultsObject = JSON.parse(cachedResults);
      if (JSON.stringify(cachedResultsObject) === JSON.stringify(results)) {
        setIsSaving(false);
        setIsEditing(false);
        return;
      } else {
        console.log('Results have changed. Saving...');
        try {
          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/results`, {
            idUtilisateur: userId,
            idTableau: id,
            results
          });
          console.log(response.data);
          localStorage.setItem('results' + id, JSON.stringify(results));
        } catch (error) {
          console.error(error);
        }
        setIsSaving(false);
        setIsEditing(false);
      }
    }
  };

  const handleStarClick = async () => {
    const isFavorite = favorites.some(favorite => favorite.idtableau === tableau.idtableau);
    try {
      localStorage.removeItem('favoritesCache');
      localStorage.removeItem('favorites');
      localStorage.removeItem('favorites' + id);
      if (isFavorite) {
        // If the tableau is already a favorite, remove it from the favorites
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/favoris/remove`, {
          idUtilisateur: localStorage.getItem('userId'),
          idTableau: tableau.idtableau
        });
      } else {
        // If the tableau is not a favorite, add it to the favorites
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/favoris`, {
          idUtilisateur: localStorage.getItem('userId'),
          idTableau: tableau.idtableau
        });
      }
      // Re-fetch favorites after adding or removing a favorite
      const favoritesResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/favoris`, {
        params: {
          idUtilisateur: localStorage.getItem('userId')
        }
      });
      setFavorites(favoritesResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  function adviseBestClassToImprove(tableau) {
    if (!tableau || !tableau.resourceArray || !tableau.coefficients) {
      console.error('Tableau data is incomplete or missing.');
      return 'Unable to provide advice due to incomplete data.';
    }
    const hypotheticalImprovement = 2;
    let improvements = tableau.resourceArray.map(resource => {
      const currentGrade = tableau.results ? tableau.results[resource.resourceNumber] || 0 : 0;
      const improvedGrade = currentGrade + hypotheticalImprovement;
      const coefficient = tableau.coefficients.find(coefficient => coefficient.resourceNumber === resource.resourceNumber);
      const impact = coefficient ? improvedGrade * (coefficient.coefficient || 0) : 0;
      return {resourceName: resource.resourceName, impact};
    });
    let lowestClass = null;
    let improvementNeeded = null;
    let uesBelowTen = tableau.ueArray.filter(ue => calculateAverage(ue.ueNumber) < 10);
    let classesWithHighestWeight = [];
    for (let i = 0; i < uesBelowTen.length; i++) {
      const ue = uesBelowTen[i];
      const classesInUe = tableau.coefficients.filter(coefficient => coefficient.ueNumber === ue.ueNumber);
      console.log("ADVICE classesInUe", classesInUe);
      const UEAverage = calculateAverage(ue.ueNumber);
      console.log("ADVICE UEAverage", UEAverage);
      let highestWeightClass = null;
      let highestWeight = 0;
      for (let j = 0; j < classesInUe.length; j++) {
        const coefficient = classesInUe[j];
        const classResource = tableau.resourceArray.find(resource => resource.resourceNumber === coefficient.resourceNumber);
        const className = classResource.resourceName;
        const classAverage = results[coefficient.resourceNumber];
        const improvementNeeded = (10 - UEAverage) * coefficient.coefficient;
        const weight = (20 - classAverage) * coefficient.coefficient;
        console.log("ADVICE improvementNeeded for", className, improvementNeeded, " weight ", weight);
        //add className to resourcesToHighlight
        resourcesToHighlight.push(classResource);
        if (weight > highestWeight) {
          highestWeight = weight;
          highestWeightClass = {
            className,
            weight,
            improvementNeeded
          };
        }
      }
      if (highestWeightClass) {
        classesWithHighestWeight.push(highestWeightClass);
      }
    }

// Log the classes with the highest weight for each UE
    console.log("Classes with the highest weight in each UE:", classesWithHighestWeight);

    function calculateImprovementForEachClass(tableau) {
      let improvementsNeeded = [];

      classesWithHighestWeight.forEach(classWithHighestWeight => {
        // Safely find the coefficient object with optional chaining
        const coefficient = tableau.coefficients.find(coefficient => coefficient.resourceNumber === classWithHighestWeight.className.resourceNumber);
        if (!coefficient) {
          console.error('Coefficient not found for resourceNumber:', classWithHighestWeight.className.resourceNumber);
          return; // Skip this iteration if the coefficient object is not found
        }

        const ueNumber = coefficient.ueNumber;
        const ueCoefficients = tableau.coefficients.filter(coefficient => coefficient.ueNumber === ueNumber);
        const totalUeCoefficient = ueCoefficients.reduce((sum, coefficient) => sum + coefficient.coefficient, 0);
        const currentUeAverage = calculateAverage(ueNumber);
        const differenceNeeded = 10 - currentUeAverage;
        const totalPointsNeeded = differenceNeeded * totalUeCoefficient;

        // Safely find the class coefficient with optional chaining
        const classCoefficient = ueCoefficients.find(coefficient => coefficient.resourceNumber === classWithHighestWeight.className.resourceNumber)?.coefficient || 0;
        const classCurrentGrade = tableau.results[classWithHighestWeight.className.resourceNumber] || 0;
        const classPointsNeeded = totalPointsNeeded / classCoefficient;
        const classGradeImprovementNeeded = classPointsNeeded - classCurrentGrade;

        improvementsNeeded.push({
          className: classWithHighestWeight.className,
          improvementNeeded: classGradeImprovementNeeded > 0 ? classGradeImprovementNeeded : 0 // Ensure we don't suggest lowering grades
        });
      });

      return improvementsNeeded;
    }

    const improvementsADVICE = calculateImprovementForEachClass(tableau);
    console.log("ADVICE improvements", improvementsADVICE);

    //if there is only one UE then give advice for this UE even if its over 10
    if (tableau.ueArray.length === 1) {
      const ue = tableau.ueArray[0];
      const classesInUe = tableau.coefficients.filter(coefficient => coefficient.ueNumber === ue.ueNumber);
      const UEAverage = calculateAverage(ue.ueNumber);
      let highestWeightClass = null;
      let highestWeight = 0;
      for (let j = 0; j < classesInUe.length; j++) {
        const coefficient = classesInUe[j];
        const classResource = tableau.resourceArray.find(resource => resource.resourceNumber === coefficient.resourceNumber);
        const className = classResource.resourceName;
        const classAverage = results[coefficient.resourceNumber];
        const improvementNeeded = (10 - UEAverage) * coefficient.coefficient;
        const weight = (20 - classAverage) * coefficient.coefficient;
        console.log("ADVICE improvementNeeded for", className, improvementNeeded, " weight ", weight);
        //add className to resourcesToHighlight
        resourcesToHighlight.push(classResource);
        if (weight > highestWeight) {
          highestWeight = weight;
          highestWeightClass = {
            className,
            weight,
            improvementNeeded
          };
        }
      }
      if (highestWeightClass) {
        classesWithHighestWeight.push(highestWeightClass);
      }
    }

    if (uesBelowTen.length > 0) {
      const lowestUe = uesBelowTen.reduce((prev, current) => (calculateAverage(prev.ueNumber) < calculateAverage(current.ueNumber) ? prev : current));
      const lowestUeAverage = calculateAverage(lowestUe.ueNumber);
      const classesInLowestUe = tableau.coefficients.filter(coefficient => coefficient.ueNumber === lowestUe.ueNumber);
      const lowestClassCoefficient = classesInLowestUe.reduce((prev, current) => (results[prev.resourceNumber] < results[current.resourceNumber] ? prev : current));
      const lowestClassResource = tableau.resourceArray.find(resource => resource.resourceNumber === lowestClassCoefficient.resourceNumber);
      lowestClass = lowestClassResource.resourceName;
      const lowestClassAverage = results[lowestClassCoefficient.resourceNumber];
      improvementNeeded = (10 - lowestUeAverage) * classesInLowestUe.reduce((sum, coefficient) => sum + coefficient.coefficient, 0);
      //round improvement needed to 2 decimal places
      improvementNeeded = Math.round(improvementNeeded * 100) / 100;

      console.log("improvements", improvements);
      console.log("uesBelowTen", uesBelowTen);
      console.log("lowestUe", lowestUe);
      console.log("lowestUeAverage", lowestUeAverage);
      console.log("classesInLowestUe", classesInLowestUe);
      console.log("lowestClass", lowestClass);
      console.log("lowestClassAverage", lowestClassAverage);
      console.log("improvementNeeded", improvementNeeded);
    }


    // Sort by impact in descending order
    improvements.sort((a, b) => b.impact - a.impact);

    // Calculate 20% of the total classes, rounding up
    const classesToAdviseCount = Math.ceil(tableau.resourceArray.length * 0.2);

    // Select the top classes based on the calculated number
    const classesToImprove = improvements.slice(0, classesToAdviseCount);

    let advice = '';
    if (classesToImprove.length === 0) {
      advice = 'Félicitations! Vous avez déjà une excellente moyenne dans toutes les classes.';
    } else {
      const classNames = classesToImprove.map(c => c.resourceName).join(', ');
      //set the classes that need to be highlighted
      //setResourcesToHighlight(classesWithHighestWeight.map(c => c.className));
      //also advice to improve in the lowest class of the ue that has the lowest average
      advice = `Pour améliorer votre moyenne, essayez de vous concentrer sur les matières suivantes: ${classesWithHighestWeight.map(c => c.className).join(', ')}. `;
      //for each lowest class tell by how much they need to improve to get over 10
      if (lowestClass && improvementNeeded) {
        //advice += `Pour passer au dessus de 10 dans les UEs suivantes, vous devez améliorer vos notes de: ${lowestClass} de ${improvementNeeded} points.`;
      }
    }
    return advice;
  }

  const handleShareLink = () => {
    const tableauId = tableau.idtableau.toString(); // Assuming tableau.idtableau is available
    const encryptedId = CryptoJS.AES.encrypt(tableauId, 'secret-key').toString();
    const encodedId = encodeURIComponent(encryptedId);
    const originalUrl = window.location.origin;
    const shareableLink = `${originalUrl}/tableau/${encodedId}`;
    navigator.clipboard.writeText(shareableLink)
      .then(() => {
        setIsLinkCopied(true);
        toast("Le lien a été copié dans le presse-papiers", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        setTimeout(() => setIsLinkCopied(false), 3000); // Reset state after 3 seconds
      })
      .catch(err => console.error('Could not copy link: ', err));
  };

  const redirectToLogin = () => {
    const currentPath = window.location.pathname;
    navigate(`/login?redirect=${encodeURIComponent(currentPath)}`);
  }

  return (
    <Card className="w-full h-full sm:h-fit m-auto overflow-hidden sm:mx-6">
      <CardHeader className="w-full flex flex-col sm:flex-row gap-2 justify-between mt-2 sm:mt-0 p-3 sm:p-6 sm:!pb-3">
        <div
          className="whitespace-nowrap font-bold text-xs sm:text-2xl flex flex-col sm:flex-row mb-3 !my-auto">
          <CardTitle className="text-lg sm:text-2xl whitespace-nowrap mb-2 sm:mb-0 whitespace-normal">
            <i className="fas fa-graduation-cap mr-2 mt-1"></i>
            {tableau.formationlevel} {tableau.nomtableau} : semestre {tableau.semester.replace('S', '')}
            <i
              className={`fas fa-caret-down pt-1 ml-2 transition-transform duration-500 hover:cursor-pointer ${showDescription ? 'rotate-180' : ''}`}
              onClick={() => setShowDescription(!showDescription)}></i>
          </CardTitle>
          <div className="save-indicator sm:ml-2 my-auto">
            {!userId ? (
              <Badge
                variant="danger"
                className="text-base border-red-500 px-1 !pr-2 bg-red-100 text-red-500 select-none hover:cursor-pointer dark:bg-red-900 dark:text-red-500"
                onClick={redirectToLogin}>
                <i className="fas fa-times-circle mx-0.5" title="Non connecté"></i>
                <span>Non connecté</span>
              </Badge>
            ) : isSaving ? (
              <Badge
                className="text-base border-cyan-500 px-1 !pr-2 bg-cyan-100 text-cyan-500 select-none dark:bg-cyan-900 dark:text-cyan-500">
                <i className="fas fa-spinner fa-spin mx-0.5" title="Enregistrement en cours..."></i>
                <span>Enregistrement </span><span className="hidden sm:inline ml-1"> en cours...</span>
              </Badge>
            ) : isEditing ? (
              <Badge
                className="text-base border-orange-500 px-1 !pr-2 bg-orange-100 text-orange-500 select-none dark:bg-orange-900 dark:text-orange-500">
                <i className="fas fa-dot-circle mx-0.5" title="Modification en cours..."></i>
                <span>Modification </span><span className="hidden sm:inline ml-1"> en cours...</span>
              </Badge>
            ) : (
              <Badge
                variant="success"
                className="text-base border-green-500 px-1 !pr-2 bg-green-100 text-green-500 select-none dark:bg-green-900 dark:text-green-500">
                <i className="fas fa-check-circle mx-0.5" title="Enregistré!"></i>
                <span>Enregistré </span><span className="hidden sm:inline ml-1">dans le cloud</span>
              </Badge>
            )}
          </div>
        </div>
        <div
          className="flex flex-row w-full h-0 sm:w-fit justify-end gap-2 !mt-0 transform -translate-y-11 sm:transform-none">
          {userId && (
            <>
              <button onClick={handleStarClick}
                      className="!p-2.5">
                <i
                  className={favorites.some(favorite => favorite.idtableau === tableau.idtableau) ? "fas fa-star transform rotate-[70deg] text--primary transition" : "far fa-star transition"}></i>
              </button>
            </>
          )}
          <Button
            variant="outline"
            style={buttonAnimation}
            onClick={handleShareLink}
          >
            {isLinkCopied ? (
              <>
                <i className="fas fa-check mr-0 sm:mr-2"></i><span className="hidden sm:inline">Lien copié</span>
              </>
            ) : (
              <>
                <i className="fas fa-share-alt mr-0 sm:mr-2"></i><span className="hidden sm:inline">Partager</span>
              </>
            )}
          </Button>
          {isAdmin && (
            <Dialog>
              <DialogTrigger asChild>
                <Button>
                  <i className="fas fa-cogs mr-0 sm:mr-2"></i>
                  <span className="hidden sm:inline">Admin Actions</span>
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogTitle>Admin Actions</DialogTitle>
                <DialogDescription>
                  <Label>Certified</Label>
                  <Checkbox checked={isCertified} onCheckedChange={handleCertificationChange}>
                  </Checkbox>
                  <Button onClick={handleDeleteTableau}>Delete Tableau</Button>
                </DialogDescription>
                <DialogFooter>
                  <Button onClick={() => setIsOpen(false)}>Close</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </CardHeader>

      <div
        style={{maxHeight: showDescription ? '500px' : '0px'}}
        className="transition-max-height duration-500 ease-in-out overflow-hidden px-3 sm:px-6">
        <p className="description-text flex flex-col mb-3 font-semibold">
          <p>Créé par : <Badge>
            {tableau.login}
          </Badge></p>
          <div>Description : <span className="font-normal">
            {tableau.descriptif}
          </span></div>
        </p>
      </div>

      <CardContent className="p-2 sm:!pt-3 sm:p-6">
        <table>
          <thead>
          <tr>
            <th className="!max-w-12 sm:!min-w-80 sm:!w-80">
              Matière
            </th>
            <th className="!w-8 !max-w-8 sm:!w-32 sm:!min-w-20">Note</th>
            {tableau.ueArray.map((ue, index) => (
              <th key={index} className="!min-w-8 sm:!min-w-20">{ue.ueName}</th>
            ))}
          </tr>
          </thead>
          <tbody>
          {tableau.resourceArray.map((resource, index) => (
            <tr key={index}>
              <td className="px-2 bg-muted">{resource.resourceName}</td>
              <td>
                <input
                  type="number"
                  onChange={(event) => handleInputChange(resource.resourceNumber, event)}
                  onBlur={() => {
                    saveResults();
                    setFocusedInput(null);
                  }}
                  onFocus={() => setFocusedInput(resource.resourceNumber)}
                  value={results[resource.resourceNumber] || ''}
                  placeholder="Note"
                  className="border-none rounded-none shadow-none !w-8 !max-w-8 sm:w-32 sm:min-w-20 bg-muted"
                />
              </td>
              {tableau.ueArray.map((ue, index) => {
                const coefficient = tableau.coefficients.find(coefficient => coefficient.ueNumber === ue.ueNumber && coefficient.resourceNumber === resource.resourceNumber);
                return (
                  <td key={index}
                      className={`text-center align-middle bg-muted ${focusedInput === resource.resourceNumber && coefficient ? 'animate-pulse !bg-yellow-100 text-yellow-500 dark:!bg-yellow-900' : ''}`}>{coefficient ? coefficient.coefficient : '-'}</td>
                );
              })}
            </tr>
          ))}
          <tr>
            <td className="text-center bg-muted align-middle font-bold">Moyenne</td>
            <td className="text-center bg-muted">
              {
                (tableau.ueArray.reduce((acc, ue) => acc + parseFloat(calculateAverage(ue.ueNumber)), 0) / tableau.ueArray.length).toFixed(2)
              }
            </td>
            {tableau.ueArray.map((ue, index) => (
              <td key={index}
                  className={`text-center bg-muted align-middle ${calculateAverage(ue.ueNumber) >= 10 ? '!bg-green-100 text-green-500 dark:!bg-green-900' : '!bg-red-100 text-red-500 dark:!bg-red-900'} ${focusedInput && tableau.coefficients.find(coefficient => coefficient.ueNumber === ue.ueNumber && coefficient.resourceNumber === focusedInput) ? 'animate-pulse bg-yellow-200' : ''}`}>
                {calculateAverage(ue.ueNumber)}
              </td>
            ))}
          </tr>
          </tbody>
        </table>
      </CardContent>
      <CardFooter className="px-3 pt-2 sm:!pt-0 sm:p-6">
        {
          userId && (
            <>
              <div className="flex flex-col gap-2 items-start">
                <p>Demander à l'IA pour obtenir votre rang et des conseils personnalisés.</p>
                <ShinyRotatingBorderButtonVariant1
                  onClick={fetchUserRankAndCount}
                  disabled={isTyping}
                  className={aiStarted ? 'hidden' : ''}
                  icon="fas fa-robot"
                >
                  Demander à l'IA
                </ShinyRotatingBorderButtonVariant1>

                {
                  aiStarted && (
                    <>
                      <div
                        className="relative w-fit rounded-xl bg-cyan-100 text-cyan-500 px-3 py-1 mt-3 hover:cursor-pointer group overflow-hidden dark:bg-cyan-900 dark:text-cyan-500">
                        <div className="flex justify-center items-center">
                          {
                            isLoading ? (
                              <i className="fas fa-spinner fa-spin"></i>
                            ) : (
                              <>
                                <i className="fas fa-robot mr-2"></i>
                              </>
                            )
                          }
                          <div dangerouslySetInnerHTML={{__html: displayedText}}/>
                        </div>
                        <button
                          onClick={fetchUserRankAndCount}
                          disabled={isTyping}
                          className="absolute h-full w-full backdrop-blur opacity-0 group-hover:opacity-100 mx-auto top-0 left-0 right-0 text-sm transition-opacity duration-300 ease-in-out">
                          <i className="fas fa-redo-alt mr-2"></i>
                          Régénérer
                        </button>
                      </div>
                    </>
                  )
                }
              </div>
            </>
          ) || (
            <>
              <div
                className="hidden sm:inline text-base border bg-blue-50 text-blue-500 dark:bg-blue-900 dark:text-blue-50 p-3 rounded-lg border-blue-500">
                <p><i className="fas fa-info-circle mr-2"></i>Connectez-vous pour pouvoir enregistrer vos notes et
                  obtenir des conseils personnalisés grâce à
                  l'Intelligence Artificielle.<Button onClick={redirectToLogin} className="ml-2">
                    <i className="fas fa-sign-in-alt mr-2"></i>
                    Connexion
                  </Button></p>
              </div>
            </>
          )
        }
      </CardFooter>
    </Card>
  )
    ;
};

export default TableauPage;