// front/src/Dashboard.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import TableauCard from "./TableauCard";

function Dashboard({onLogout}) {
  const navigate = useNavigate();
  const userName = localStorage.getItem('userName'); // Retrieve the user's name
  const greetings = ['Ravie de vous revoir!', 'Content de vous voir!', 'Heureux de vous retrouver!'];
  const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)]; // Select a random greeting

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/favoris`, {
          params: {
            idUtilisateur: localStorage.getItem('userId')
          }
        });
        console.log(response.data);
        localStorage.setItem('favorites', JSON.stringify(response.data));
        setFavorites(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTableaux = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/tableaux/bysUser`, {
          params: {
            idUtilisateur: localStorage.getItem('userId')
          }
        });
        console.log(response.data);
        localStorage.setItem('tableaux', JSON.stringify(response.data));
        setFavorites(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const storedFavorites = localStorage.getItem('favorites');
    const storedTableaux = localStorage.getItem('tableaux');

    if (storedFavorites) {
      setFavorites(JSON.parse(storedFavorites));
    } else {
      fetchFavorites();
    }

    if (storedTableaux) {
      setFavorites(JSON.parse(storedTableaux));
    } else {
      fetchTableaux();
    }
  }, []);

  const handleTableClick = (id) => {
    navigate('/tableau/' + id);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="container">
        <h1>Bonjour {userName}</h1>
        <p>{randomGreeting}</p>
      </div>
      <div className="flex flex-row gap-4 h-fit my-auto">
        <div className="container">
          <h2>Vos tableaux récents</h2>
          {favorites.map((favorite, index) => (
            <TableauCard key={index} tableau={favorite} onClick={() => handleTableClick(favorite.idtableau)}/>
          ))}
        </div>
        <div className="container">
          <h2>Vos tableaux favoris</h2>
          {favorites.map((favorite, index) => (
            <div key={index}
                 className="group hover:cursor-pointer hover:bg-gray-200 p-2 m-2 rounded-md border shadow transition flex flex-row items-center gap-1 justify-between hover:transform hover:scale-105"
                 onClick={() => handleTableClick(favorite.idtableau)}>
              <div className="flex flex-row items-center gap-1">
                <i className="fas fa-star text-gray-400 mr-2"></i>
                <h2>{favorite.formationlevel} {favorite.nomtableau} {favorite.semester}</h2>
              </div>
              <i className="fas fa-arrow-right text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity"></i>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;