import React, {useState} from 'react';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "./@/components/ui/card";
import {Button} from "./@/components/ui/button";
import {toast} from 'react-toastify';
import {InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator} from './@/components/ui/input-otp';

function Verify() {
  const [code, setCode] = useState(Array(6).fill(''));
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;

  const handleChange = (index, value) => {
    console.log('Index:', index);
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    console.log('New code:', newCode);
  };

  const showCode = () => {
    let code = '';
    const otpSlots = document.querySelectorAll('.otp-slot input');
    otpSlots.forEach(slot => {
      code += slot.value;
    });
    console.log('Verification code:', code);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const verificationCode = code.join('');
    console.log('Verification code:', verificationCode);

    if (verificationCode.length !== 6) {
      toast.error("Le code de vérification doit être composé de 6 caractères.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/verify`, {email, code: verificationCode});
      if (response.status === 200) {
        toast.success('Verification successful');
        navigate('/dashboard');
      } else {
        toast.error('Invalid verification code');
      }
    } catch (error) {
      console.error('Verification error:', error);
      toast.error('Server error');
    }
  };

  return (
    <Card className="m-auto w-full sm:w-96">
      <CardHeader>
        <CardTitle>Verify Your Account</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <InputOTP maxLength={6}>
            <InputOTPGroup>
              {Array.from({length: 3}).map((_, index) => (
                <InputOTPSlot
                  className={`otp-slot`}
                  key={index}
                  index={index}
                  value={code[index]}
                  onChange={(e) => handleChange(index, e.target.value)}
                />
              ))}
            </InputOTPGroup>
            <InputOTPSeparator/>
            <InputOTPGroup>
              {Array.from({length: 3}).map((_, index) => (
                <InputOTPSlot
                  className={`otp-slot`}
                  key={index + 3}
                  index={index + 3}
                  value={code[index + 3]}
                  onChange={(e) => handleChange(index + 3, e.target.value)}
                />
              ))}
            </InputOTPGroup>
          </InputOTP>
          <Button onClick={() => showCode()} className="mx-2">Log code</Button>
          <Button type="submit" className="mt-4">Verify</Button>
        </form>
      </CardContent>
      <CardFooter>
        <p>Please enter the 6-digit code sent to your email.</p>
      </CardFooter>
    </Card>
  );
}

export default Verify;